#footer {
    justify-content: space-between;
    min-height: fit-content;
    padding: 3em;
}

a {
    text-decoration: none;
}

.social-media {
    font-size: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.social-media>a {
    display: flex;
    flex-direction: column;
    gap: 0.25em;
    justify-content: center;
    align-items: center;
}

.social-media>a, .social-media>a > i {
    color: var(--blue);
}

.social-media>a>span {
    font-size: 1rem;
}

.social-media>a:hover {
    transform: scale(1.1);
}

.custom-svg path {
    fill: #05396b !important;
    /* !important, aby nadpisać domyślny kolor */
}