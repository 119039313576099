.app-container {
    position: relative;
    width: 200px;
    height: calc(170px + 1rem + 2 * 1.25rem);
    perspective: 1000px; /* Efekt 3D */
}

.app-card, .app-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden; /* Ukrywa tył karty */
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    border-radius: 0.5rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.app-card-back {
    background: var(--blue);
    transform: rotateY(90deg);
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 0.5rem;
}


.app-container:hover .app-card {
    transform: rotateY(90deg);
    opacity: 0;
    transition-delay: 0s; /* Natychmiastowa zmiana */
}

.app-container:hover .app-card-back {
    transform: rotateY(0deg);
    opacity: 1;
    transition-delay: 0.15s; /* Opóźnienie, by najpierw ukryć pierwszą kartę */
}

.app-container:not(:hover) .app-card {
    transform: rotateY(0deg);
    opacity: 1;
    transition-delay: 0.15s; /* Odwrotny efekt */
}

.app-container:not(:hover) .app-card-back {
    transform: rotateY(-90deg);
    opacity: 0;
    transition-delay: 0s;
}

.app-card > img {
    width: 200px;
    height: 170px;
    aspect-ratio: 1;
    object-fit: cover;
    margin: 0;
    border-radius: inherit;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    display: block;
}

.app-card > p {
    background-color: var(--blue);
    color: white;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.25rem;
    margin: 0;
    border-radius: inherit;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.in-app {
    position: absolute;
    z-index: 2;
    transform: scale(0.85);
    margin-bottom: 2.5rem;
    text-align: center;
    font-size: 0.75rem;
}