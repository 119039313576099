.logo>img {
    height: 55px;
    margin: 1em 0;
}

.menu {
    background-color: white;
}

.flex-row-center-space-between {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 0.5em;
}

.menu-links {
    display: flex;
    gap: 1rem;
    align-items: center;
}

a[href="/shop"] {
    background-color: var(--green);
    padding: 0.5rem;
    border-radius: 0.25rem;
}

a[href="/shop"]:hover {
    transform: scale(1.1);
}

a {
    text-decoration: none;
    color: var(--blue);
}

a.active {
    border-bottom: 1px solid var(--green);
}
.hamburger-icon {
    display: none;
    /* Hidden by default */
    cursor: pointer;
    font-size: 2rem;
    color: #666666;
}

.mobile-menu-container {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

/* Mobile styles */
@media (max-width: 768px) {
    .menu-links {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 60px;
        right: 20px;
        background-color: white;
        width: 200px;
        /*border: 1px solid #ccc; !* Optional border *!*/
        padding: 10px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    }

    .menu-links.hidden {
        display: none;
    }

    .menu-links.visible {
        display: flex;
        z-index: 10;
        justify-content: center;
        align-items: flex-end;
        text-align: right;
        gap: 1rem;
        border-radius: 0.25em;
        width: auto;
        margin-top: 1em;
        padding: 1rem 2rem 1rem 4rem;
    }

    .menu-links.visible>select {
        align-self: end;
    }

    .hamburger-icon {
        display: block;
    }
}