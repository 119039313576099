.form-modal {
    background-color: white;
    color: black;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
    width: 40% !important;
    height: 40%;
    margin: auto;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1em;
}


.form-modal > #close {
    position: absolute;
    top: -1em;
    right: -1em;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    color: white;
    border-radius: 1em;
    width: 2em;
    height: 2em;
}

.form-modal > p {
    text-align: center;
}

@media (max-width: 576px) {
    .form-modal {
        width: 90% !important;
    }
}