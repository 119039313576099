.language-picker {
    position: relative;
}

.selected-option {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
}

.flag {
    display: flex;
    align-items: center;
}

img.flag {
    width: 20px;
    height: 15px;
}

.language-name {
    flex-grow: 1;
}

.arrow {
    transition: transform 0.2s;
}

.arrow.up {
    transform: rotate(180deg);
}

.arrow.down {
    transform: rotate(0deg);
}

.options-list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin: 0;
    padding: 0;
    list-style: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
}

.option {
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
}

.option:hover {
    background-color: #f0f0f0;
}

.option.selected {
    background-color: #e0e0e0;
}