section#offert>div>img {
    border-radius: 1em;
}


p > a#internship {
    padding: 1em 3em;
    background-color: var(--green);
    border-radius: 1em;
    text-decoration: none;
    color: var(--blue);
    font-weight: 600;
}


p > a {
    text-decoration: none;
    color: white;
    padding: 0.1rem;
    border-radius: 0.25em;
    border: 1px solid rgba(255, 255, 255, 0.327);
}


.how-it-works>.steps {
    row-gap: 2em;
}


.steps > div > p {
    text-align: center;
}