.email-field {
    font-size: 1.5rem;
    display: flex;
    flex-direction: row;
    gap: 1em;
    color: var(--blue);
    margin-top: 2em;
    margin-bottom: 2em;
    font-size: clamp(0.5rem, 5vw, 2rem);
}

@media (min-width: 576px) {
    .email-field {
        font-size: clamp(16px, 3vw, 2rem);
    }
}

.error-text {
    color: red;
}

.contact-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

form {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    margin: 1em;
    border-radius: 0.5em;
    padding: 2em;
    font-weight: 400;
    color: var(--blue);
    background-color: #FBFCF8;
}


form>button {
    background-color: var(--green);
    padding: 1em 4em;
    border-radius: 1em;
    color: var(--blue) !important;
    border: none;
    margin: auto;
}

form>button:hover {
    cursor: pointer;
}

form>input,
form>textarea {
    padding: 0.5rem;
    border: 1px solid;
    border-color: var(--grey);
    border-radius: 0.25rem;
}