img#team-photo {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 1em
}

.members {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2em;
}

.member {
    display: flex;
    gap: 2em;
}

.member > p {
    width: 200px;
    height: 200px;
    margin-left: calc(-200px - 2em);
    z-index: -1;
}

.member.team p{
    margin-left: 0;
    z-index: 0;
}