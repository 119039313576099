.light-green-background {
    background: linear-gradient(to bottom, var(--light-green), white);
}

#app-logo {
    height: 3rem;
}

#mobile-app-mockup {
    max-height: 50vh;
}

.center-verticaly {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1rem;
}

.center-horizontaly {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.p-1 {
    padding: 1rem;
}
.download-app-link-box {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 0.5rem;
    background-color: white;
    background: white;
    padding: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

div.download-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

div.download-text>p {
    font-size: 1.5rem;
    font-family: 'Josefin Sans';
}

div.download-text>p:first-of-type {
    font-weight: 200;
}

.download-text p:nth-of-type(2) {
    color: var(--blue);
    font-weight: 400;
}

.download-text p:nth-of-type(2) > span {
    color: var(--green);
}


/* RECTLANGE */

.position-relative {
    position: relative; /* Pozwala na absolutne pozycjonowanie dzieci */
}

.position-relative > img {
    z-index: 1;
}

.rectangle {
    width: 100px;
    height: 100px;
    background-color: #b0f5b0; /*Jasny zielony*/
    background: linear-gradient(to bottom right, #7CE187, #DEFFE2);

    border-radius: 12px; /* Zaokrąglenie rogów */
    box-shadow: inset 0 0 20px rgba(0, 255, 0, 0.3); /* Wewnętrzna poświata */
    position: absolute; /* Dzięki temu mogą być w tle */
    animation: pulse 2s infinite alternate ease-in-out;
    opacity: 0.6; /* Trochę przezroczystości */
}

/* Pozycjonowanie każdego kwadratu */
.rect1 {
    top: calc(35% - 100px);
    left: calc(25% - 100px);
    width: 170px;
    height: 170px;
}

.rect2 {
    top: 50px;
    right: 40px;
}

.rect3 {
    bottom: 15%;
    left: 15%;
}

.rect4 {
    bottom: calc(35% - 100px);
    right: calc(35% - 100px);
    width: 180px;
    height: 180px;
}

.rect5{
    bottom: 5%;
    left: 5%;
    width: 30px;
    height: 30px;
}

.rect6{
    bottom: 30%;
    left: 0;
    width: 60px;
    height: 60px;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.1);
    }
}


.android, .apple {
    height: 45px;
}

.download-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    gap: 1em;
    flex-wrap: wrap;
}

/* Styl dla Swipera */
.swiper {
    width: 100%;
    padding: 1em 1rem; /* Dopasowanie do paddingu .container */
  }
  
  /* Styl dla slajdów */
  .swiper-slide {
    width: auto !important; /* Ważne, aby Swiper nie nadpisywał szerokości */
  }