* {
    margin: 0;
    box-sizing: border-box;
    font-family: "Josefin Sans";
}

:root {
    --light-green: #f3f7eb;
    --blue: #05396b;
    --light-blue: #07539c;
    --grey: #ededed;
    --green: #7ce187;
    --gradient-green: #6dd578;
    --dark-grey: #A4A999;
}

/* Kontener główny siatki */
.container {
    width: 100%;
    max-width: 1200px;
    /* Maksymalna szerokość jak w Bootstrapie */
    margin: 0 auto;
    /* Wyśrodkowanie */
    padding: 0 15px;
    /* Padding boczny jak w Bootstrapie */
    margin-bottom: 1rem;
}

.container:last-child {
    margin-bottom: 0;
}

/* Definicja siatki 12-kolumnowej */
.row {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    /* 12 równych kolumn */
    gap: 15px;
    /* Odstęp między kolumnami, jak gutter w Bootstrapie */
}

/* Klasy dla kolumn (col-1 do col-12) */
.col-1 {
    grid-column: span 1;
}

.col-2 {
    grid-column: span 2;
}

.col-3 {
    grid-column: span 3;
}

.col-4 {
    grid-column: span 4;
}

.col-5 {
    grid-column: span 5;
}

.col-6 {
    grid-column: span 6;
}

.col-7 {
    grid-column: span 7;
}

.col-8 {
    grid-column: span 8;
}

.col-9 {
    grid-column: span 9;
}

.col-10 {
    grid-column: span 10;
}

.col-11 {
    grid-column: span 11;
}

.col-12 {
    grid-column: span 12;
}

/* Responsywność - Breakpointy jak w Bootstrapie */
/* Mobile: domyślnie wszystkie kolumny na pełną szerokość */
@media (max-width: 576px) {

    .col-1,
    .col-2,
    .col-3,
    .col-4,
    .col-5,
    .col-6,
    .col-7,
    .col-8,
    .col-9,
    .col-10,
    .col-11,
    .col-12 {
        grid-column: span 12;
        /* Pełna szerokość na mobile */
    }
}

/* Tablet: od 576px do 768px */
@media (min-width: 576px) and (max-width: 768px) {
    .col-sm-1 {
        grid-column: span 1;
    }

    .col-sm-2 {
        grid-column: span 2;
    }

    .col-sm-3 {
        grid-column: span 3;
    }

    .col-sm-4 {
        grid-column: span 4;
    }

    .col-sm-5 {
        grid-column: span 5;
    }

    .col-sm-6 {
        grid-column: span 6;
    }

    .col-sm-7 {
        grid-column: span 7;
    }

    .col-sm-8 {
        grid-column: span 8;
    }

    .col-sm-9 {
        grid-column: span 9;
    }

    .col-sm-10 {
        grid-column: span 10;
    }

    .col-sm-11 {
        grid-column: span 11;
    }

    .col-sm-12 {
        grid-column: span 12;
    }
}

/* Desktop: od 768px w górę - domyślne klasy col-* działają */

.m-1 {
    margin: 1rem;
}

.m-2 {
    margin: 2rem;
}

.m-3 {
    margin: 3rem;
}

.m-4 {
    margin: 4rem;
}

.m-4-auto {
    margin: 4rem auto;
}

.mt-1 {
    margin-top: 1rem;
}

.mt-2 {
    margin-top: 2rem;
}

h1 {
    font-weight: 600;
    color: var(--blue);
    margin-top: 1rem;
    margin-bottom: 1rem;
}

h2 {
    font-weight: 400;
    color: var(--dark-grey);
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.center {
    text-align: center;
}


/* UKRYWANIE ELEMENTÓW */

/* Podstawowe klasy wyświetlania */
.d-none {
    display: none !important; /* Ukrywa element na wszystkich urządzeniach */
}

.d-block {
    display: block !important; /* Pokazuje element jako block */
}

.d-grid {
    display: grid !important; /* Dla elementów siatki */
}

.d-flex {
    display: flex !important; /* Dla elementów flex */
}

/* Mobile (do 576px) */
@media (max-width: 576px) {
    .d-xs-none {
        display: none !important; /* Ukrywa na mobile */
    }
    .d-xs-block {
        display: block !important; /* Pokazuje na mobile */
    }
    .d-xs-grid {
        display: grid !important;
    }
    .d-xs-flex {
        display: flex !important;
    }
}

/* Tablet (od 576px do 768px) */
@media (min-width: 576px) {
    .d-sm-none {
        display: none !important; /* Ukrywa na tablecie i większych */
    }
    .d-sm-block {
        display: block !important; /* Pokazuje na tablecie i większych */
    }
    .d-sm-grid {
        display: grid !important;
    }
    .d-sm-flex {
        display: flex !important;
    }
}

/* Desktop (od 768px) */
@media (min-width: 768px) {
    .d-md-none {
        display: none !important; /* Ukrywa na desktopie */
    }
    .d-md-block {
        display: block !important; /* Pokazuje na desktopie */
    }
    .d-md-grid {
        display: grid !important;
    }
    .d-md-flex {
        display: flex !important;
    }
}

/* Kombinacje dla specyficznych zakresów */
/* Tylko mobile (ukryj na tablecie i desktopie) */
@media (min-width: 576px) {
    .d-xs-only {
        display: none !important;
    }
}

/* Tylko tablet (ukryj na mobile i desktopie) */
.d-sm-only {
    display: none !important;
}
@media (min-width: 576px) and (max-width: 768px) {
    .d-sm-only {
        display: block !important;
    }
}

/* Tylko desktop (ukryj na mobile i tablecie) */
.d-md-only {
    display: none !important;
}
@media (min-width: 768px) {
    .d-md-only {
        display: block !important;
    }
}